
import { defineComponent } from "vue";

import { useTest, useAddTest } from "@/utils/testUtil";

export default defineComponent({
  name: "Signin",
  async setup() {
    const { tests } = useTest();

    const { formValue, addMessage } = useAddTest();

    return {
      tests,
      addMessage,
      formValue,
    };
  },
});
