import firebase from "firebase/compat/app";
import { createStore } from "vuex";

interface Group {
  id: string;
  group: {
    name: string;
    alias: string;
  };
}
interface State {
  user: undefined | firebase.UserInfo;
  group: undefined | Group;
}
export default createStore<State>({
  state: {
    user: undefined,
    group: undefined,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setGroup(state, group) {
      state.group = group;
    },
  },
  getters: {
    isSiginedIn: (state) => {
      return state.user !== null && state.user !== undefined;
    },
    uid: (state) => {
      return state.user !== null && state.user !== undefined
        ? state.user.uid
        : null;
    },
    user: (state) => {
      return state.user !== null && state.user !== undefined
        ? state.user
        : null;
    },
    group: (state) => {
      return state.group !== null && state.group !== undefined
        ? state.group
        : null;
    },
  },
  actions: {},
  modules: {},
});
