import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.user && _ctx.isUserInfo)
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0,
        user: _ctx.user
      }, null, 8, ["user"]))
    : _createCommentVNode("", true)
}