
import firebase from "firebase/compat/app";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import SigninVerifyCode from "@/views/SigninVerifyCode.vue";

import { auth } from "@/utils/firebase9";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ConfirmationResult,
} from "firebase/auth";
import { useRouter } from "vue-router";
import { useUser } from "@/utils/utils";

export default defineComponent({
  name: "Signin",
  props: {
    groupId: { type: String, required: true },
  },
  components: {
    SigninVerifyCode,
  },
  setup(props) {
    const router = useRouter();
    const user = useUser();

    const countryCode = "+81";
    const phoneNumber = ref("");

    const isLoading = ref(false);
    const isConfirm = ref(false);

    let recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    const confirmationResult = ref<ConfirmationResult>();

    watch(user, () => {
      if (user.value != null) {
        router.push(`/${props.groupId}/user/photoupload`);
      }
    });

    onMounted(() => {
      recaptchaVerifier = new RecaptchaVerifier(auth, "signInButton", {
        size: "invisible",
        callback: (response: string) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("verified", response);
        },
      });
    });

    const SMSPhoneNumber = computed(() => {
      return countryCode + phoneNumber.value;
    });

    const errors = computed(() => {
      const ret = [];
      // const regex = /^[0-9()\-]{8,15}$/;
      const regex = /^0[789]0-?[0-9]{4}-?[0-9]{4}$/;
      if (!regex.test(phoneNumber.value)) {
        console.log(phoneNumber.value);
        ret.push("電話番号を入力してください。");
      }
      return ret;
    });

    const hasError = computed(() => {
      return errors.value.length > 0;
    });

    const handleSubmit = async () => {
      console.log("handleSubmit");
      try {
        isLoading.value = true;
        confirmationResult.value = await signInWithPhoneNumber(
          auth,
          // "+81-90-xxxx-xxxx"
          SMSPhoneNumber.value,
          recaptchaVerifier,
        );
        isConfirm.value = true;
        console.log("result", confirmationResult);
      } catch (error: any) {
        console.log("error", error.code);
        // this.errors = ["sms." + error.code];
      } finally {
        isLoading.value = false;
      }
    };

    return {
      handleSubmit,

      phoneNumber,
      SMSPhoneNumber,
      errors,
      hasError,
      confirmationResult,
      isLoading,
      isConfirm,
      user,
    };
  },
});
