
import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import { auth } from "@/utils/firebase9";
import { User as FBUser, onAuthStateChanged } from "firebase/auth";
import firebase from "firebase/compat/app";

interface User {
  user: FBUser | null;
}

export default defineComponent({
  name: "Layout",
  async setup() {
    const store = useStore();
    const user = reactive<User>({ user: null });

    onMounted(() => {
      onAuthStateChanged(auth, (fbuser) => {
        if (fbuser) {
          console.log("authStateChanged:");
          user.user = fbuser;
          store.commit("setUser", user.user);
        } else {
          store.commit("setUser", null);
        }
      });
    });

    return {
      user,
    };
  },
});
