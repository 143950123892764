
import { defineComponent, ref } from "vue";
import { auth, db } from "@/utils/firebase9";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { useUser } from "@/utils/utils";
import { useRouter } from "vue-router";
import { findGroupByName } from "@/models/groups";

export default defineComponent({
  name: "UserInfoRegistration",
  props: {
    groupId: { type: String, required: true },
  },
  setup(props) {
    const router = useRouter();
    const realName = ref("");
    const user = useUser();

    const handleSubmit = async () => {
      const group = findGroupByName(props.groupId);
      if (!group) {
        await router.push(`/${props.groupId}`);
        return;
      }

      try {
        await setDoc(doc(db, `users/${user.value.uid}`), {
          groupId: group.id,
          realName: realName.value,
          phoneNumber: auth.currentUser?.phoneNumber,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        router.push(`/${props.groupId}/user/photoupload`);
      } catch (error: any) {
        console.log("error", error.code);
      }
    };

    return {
      realName,
      handleSubmit,
    };
  },
});
