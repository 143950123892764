import {
  QueryDocumentSnapshot,
  DocumentSnapshot,
  DocumentData,
} from "firebase/firestore";

export default class FirebaseModel {
  model: QueryDocumentSnapshot | DocumentSnapshot;
  data: DocumentData;

  id: string;

  constructor(_model: QueryDocumentSnapshot | DocumentSnapshot) {
    this.data = _model.data() as DocumentData;
    this.model = _model;

    this.id = _model.id;
  }
}
