<template>
  <div class="signout_comfirm">
    <div v-if="open" class="fixed inset-0 bg-white"></div>
    <div
      v-if="open"
      class="fixed inset-0 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none"
    >
      <div class="flex w-full flex-col items-center justify-center">
        <div class="text-3xl font-semibold">サインアウト</div>
        <div class="mt-2 mt-10 text-2xl">
          今後はもう写真を<br />送りませんか？
        </div>
        <router-link
          :to="`/${groupId}/user/photoupload`"
          class="flex w-full items-center justify-center"
        >
          <button
            class="mt-10 flex h-20 w-9/12 w-60 items-center justify-center rounded-md bg-sky-400 py-3 text-2xl font-semibold text-black hover:bg-sky-500 hover:text-white"
          >
            また使う
          </button>
        </router-link>
        <button
          class="mt-10 flex h-20 w-9/12 w-60 items-center justify-center rounded-md bg-sky-400 py-3 text-2xl font-semibold text-black hover:bg-sky-500 hover:text-white"
          type="button"
          @click="signout()"
        >
          もう使わない
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";
import { auth } from "@/utils/firebase9";
import { signOut } from "firebase/auth";

export default defineComponent({
  name: "SignoutConfirm",
  props: {
    open: { type: Boolean, require: false },
    toggleModal: { type: Function, require: true },
    groupId: { type: String, required: true },
  },
  setup(props) {
    const signout = async () => {
      try {
        await signOut(auth);
        props.toggleModal();
        router.push(`/${props.groupId}`);
      } catch (error) {
        console.log("error", error.code);
        // TODO: error 処理"
      }
    };
    return {
      signout,
      // toggleModal,
    };
  },
});
</script>

<style scoped></style>
