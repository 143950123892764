import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "@/components/Layout.vue";
import Hello from "@/views/Hello.vue";
import Home from "@/views/Home.vue";
import Signin from "@/views/Signin.vue";
import About from "@/views/About.vue";
import Test from "@/views/Test.vue";
import UserInfoRegistration from "@/views/UserInfoRegistration.vue";

import GroupWrapper from "@/views/GroupWrapper.vue";

import SignedInUserWrapper from "@/views/user/SignedInUserWrapper.vue";
import PhotoUpload from "@/views/user/PhotoUpload.vue";
import PhotoList from "@/views/user/PhotoList.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Hello",
        component: Hello,
      },
      {
        path: "/test",
        name: "Test",
        component: Test,
      },
      {
        path: "/:groupId",
        name: "GroupWrapper",
        component: GroupWrapper,
        props: true,
        children: [
          {
            path: "",
            name: "Home",
            component: Home,
            props: true,
          },
          {
            path: "about",
            name: "About",
            component: About,
            props: true,
          },
          {
            path: "signin",
            name: "Signin",
            component: Signin,
            props: true,
          },
          {
            path: "userinforegistration",
            name: "UserInfoRegistration",
            component: UserInfoRegistration,
            props: true,
          },
          {
            path: "user",
            component: SignedInUserWrapper,
            props: true,
            children: [
              {
                path: "photoupload",
                name: "PhotoUpload",
                component: PhotoUpload,
                props: true,
              },
              {
                path: "photolist",
                name: "Photolist",
                component: PhotoList,
                props: true,
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
