import { reactive, onUnmounted } from "vue";
import { db } from "@/utils/firebase9";
import {
  onSnapshot,
  collection,
  addDoc,
  DocumentData,
  QuerySnapshot,
  serverTimestamp,
} from "firebase/firestore";

import Test from "@/models/test";

export const useTest = () => {
  const tests = reactive<Test[]>([]);
  const testDetacher = onSnapshot(
    collection(db, "/test"),
    async (testCollection: QuerySnapshot<DocumentData>) => {
      testCollection.docChanges().forEach((change) => {
        if (change.type === "added") {
          const testData = new Test(change.doc);
          tests.push(testData);
        }
      });
    },
  );

  onUnmounted(() => {
    testDetacher();
  });

  return {
    tests,
  };
};

export const useAddTest = () => {
  const formValue = reactive({
    message: "",
  });
  const addMessage = async () => {
    if (formValue.message == "") {
      return;
    }
    await addDoc(collection(db, "/test"), {
      message: formValue.message,
      createdAt: serverTimestamp(),
    });

    formValue.message = "";
  };
  return {
    formValue,
    addMessage,
  };
};
