
import { defineComponent, ref, watch } from "vue";
import { useUser, useGroup } from "@/utils/utils";
import router from "@/router";
import { getDoc, doc } from "firebase/firestore";
import { db } from "@/utils/firebase9";
import { findGroupById } from "@/models/groups";

export default defineComponent({
  props: {
    groupId: { type: String, required: true },
  },
  setup(props) {
    const isUserInfo = ref(false);
    const user = useUser();
    const group = useGroup();

    const redirectToHome = () => {
      alert("登録した地域でご利用ください。");
      router.push(`/`);
    };
    const loadUserData = async () => {
      const userInfo = await getDoc(doc(db, `users/${user.value.uid}`));
      const userData = userInfo.data();

      if (!userData) {
        return redirectToHome();
      }
      const userGroup = findGroupById(userData.groupId);
      if (!userGroup) {
        console.error("The Group Does Not Exist");
        return redirectToHome();
      }
      const userGroupName = userGroup.group.name;

      if (userGroupName !== props.groupId) {
        router.push(`/${userGroupName}/user/photoupload`);
      }
      if (userData.realName === void 0) {
        router.push(`/${props.groupId}/userinforegistration`);
      }
      isUserInfo.value = true;
    };

    watch(
      user,
      () => {
        if (user.value === null) {
          router.push("/" + props.groupId);
        }
        if (user.value) {
          loadUserData();
        }
      },
      { immediate: true },
    );

    return {
      user,
      isUserInfo,
    };
  },
});
