const groups = [
  {
    id: "DYGudyWmvHxAkCwGTSC3",
    group: {
      name: "yusuhara",
    },
  },
  {
    id: "gIopAxUixFBhXkqnBa6x",
    group: {
      name: "tosashimizu",
    },
  },
];

export const findGroupById = (groupId: string) => {
  return groups.find((group) => group.id === groupId);
};
export const findGroupByName = (groupName: string) => {
  return groups.find((group) => group.group.name === groupName);
};

export default groups;
