import FirebaseModel from "./firebasemodel";
import { QueryDocumentSnapshot, DocumentSnapshot } from "firebase/firestore";

export default class Test extends FirebaseModel {
  message: string;

  constructor(_model: QueryDocumentSnapshot | DocumentSnapshot) {
    super(_model);

    this.message = this.data.message;
  }
}
