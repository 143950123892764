
import { defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { useUser } from "@/utils/utils";
import router from "@/router";

import { fetchGroupData } from "@/utils/utils";

export default defineComponent({
  name: "Home",
  props: {
    groupId: { type: String, required: true },
  },
  setup(props) {
    const store = useStore();
    const group = store.state.group;

    const user = useUser();
    watch(user, (v) => {
      if (v && v.uid) {
        router.push(`/${props.groupId}/user/photoupload`);
      }
    });
    const { groupAlias } = fetchGroupData(group.id);

    return {
      group,
      groupAlias,
    };
  },
});
