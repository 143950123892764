<template>
  <div v-if="open" class="delete_comfirm">
    <div class="fixed inset-0 bg-black opacity-25"></div>
    <div class="fixed inset-0 flex items-center justify-center">
      <div
        class="relative right-5 h-96 w-9/12 flex-col rounded-lg bg-white shadow-lg"
      >
        <!--<router-link
          to="/user/photolist"
          class="flex w-full justify-center items-center"
        >-->
        <div class="flex w-full items-center justify-center">
          <button
            class="mt-10 flex h-20 w-10/12 w-60 items-center justify-center rounded-md bg-sky-400 py-3 text-2xl font-semibold text-black hover:bg-sky-500 hover:text-white"
            type="button"
            :disabled="isDeleteButtonEnabled"
            @click="onDelete()"
          >
            {{
              isDeleteButtonEnabled ? "削除中..." : "写真をバスから\n消します"
            }}
          </button>
        </div>
        <!--</router-link>-->
        <div class="mt-3 w-full whitespace-normal px-10 text-left">
          数日後にバスのiPadには表示されなくなりますが、写真データは管理者が保存しています。
        </div>
        <div class="flex w-full items-center justify-center">
          <button
            class="mt-5 flex h-20 w-10/12 items-center justify-center rounded-md bg-sky-400 text-2xl font-semibold text-black hover:bg-sky-500 hover:text-white"
            type="button"
            @click="toggleModal()"
          >
            写真をバスに載せ<br />たままにします
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { db } from "@/utils/firebase9";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";

export default defineComponent({
  name: "DeleteConfirm",
  props: {
    open: { type: Boolean },
    showModal: { type: Function, require: true },
    user: { type: Object, required: true },
    deletePhotoId: { type: String, required: true },
    showToast: { type: Function, required: true },
  },
  setup(props) {
    const isDeleteButtonEnabled = ref(false);
    const message = ref("");
    const result = ref(true);

    const toggleModal = () => {
      console.log("toggleModal");
      props.showModal();
    };

    const onDelete = async () => {
      console.log("onDelete");
      console.log(props.deletePhotoId);
      isDeleteButtonEnabled.value = true;

      const photoDoc = doc(
        db,
        `users/${props.user.uid}/photos/${props.deletePhotoId}`,
      );

      try {
        await setDoc(
          photoDoc,
          {
            deleteFlg: true,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );
        isDeleteButtonEnabled.value = false;
        message.value = "写真は数日後削除されます";
        result.value = true;
        props.showModal();
        props.showToast();
      } catch (e) {
        console.log("Error getting cached document:", e);
        isDeleteButtonEnabled.value = false;
        message.value = "写真を削除できませんでした";
        result.value = false;
        props.showModal();
        props.showToast();
      }
    };

    return {
      props,
      message,
      toggleModal,
      isDeleteButtonEnabled,
      onDelete,
      result,
    };
  },
});
</script>
