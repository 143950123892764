
import { defineComponent } from "vue";

export default defineComponent({
  name: "Toast",
  props: {
    result: { type: Boolean, require: false },
    message: { type: String, required: true },
  },
  setup(props) {
    console.log("★★★★" + props.message + "★★★★");
    return {};
  },
});
