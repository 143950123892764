
import { db } from "@/utils/firebase9";
import { doc, collection, setDoc, serverTimestamp } from "firebase/firestore";
import { computed, defineComponent, ref, watch } from "vue";
import { uploadToStorage } from "@/utils/imageUtil";
import { useStore } from "vuex";

export default defineComponent({
  name: "Preview",
  props: {
    open: { type: Boolean },
    showModal: { type: Function, required: true },
    photoImage: { type: Blob, required: true },
    user: { type: Object, required: true },
    showToast: { type: Function, required: true },
  },
  setup(props) {
    const store = useStore();
    const group = computed(() => store.state.group);
    console.log(group);

    const photoPreview = ref();
    const isUploadButtonEnabled = ref(false);
    const message = ref("");
    const result = ref(true);
    watch(
      () => props.photoImage,
      () => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          photoPreview.value.src = reader.result;
        });
        reader.readAsDataURL(props.photoImage);
      },
    );

    const toggleModal = () => {
      console.log("toggleModal");
      props.showModal();
    };

    const onUpload = async () => {
      console.log("modal onUpload");
      isUploadButtonEnabled.value = true;

      const photoDoc = doc(collection(db, `users/${props.user.uid}/photos`));
      const path = `/photos/${props.user.uid}/${photoDoc.id}/original.jpg`;

      try {
        const downloadURL = await uploadToStorage(path, props.photoImage);

        await setDoc(photoDoc, {
          photoTitle: "debug",
          photoURL: downloadURL,
          photoPath: path,
          uid: props.user.uid,
          groupId: group.value.id,
          status: 1, // TODO: fix magic number
          deleteFlg: false,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        isUploadButtonEnabled.value = false;
        message.value = "写真をバスに送りました";
        result.value = true;
        props.showModal();
        props.showToast();
      } catch (e) {
        console.log("Error getting cached document:", e);
        isUploadButtonEnabled.value = false;
        message.value = "写真を送れませんでした";
        result.value = false;
        props.showModal();
        props.showToast();
      }
    };

    return {
      props,
      photoPreview,
      isUploadButtonEnabled,
      onUpload,
      toggleModal,
      message,
      result,
    };
  },
});
