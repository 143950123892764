
import { defineComponent, watch, ref } from "vue";
import { useStore } from "vuex";
import { findGroupByName } from "@/models/groups";

export default defineComponent({
  props: {
    groupId: { type: String, required: true },
  },
  setup(props) {
    const store = useStore();
    const group = ref();

    const updateGroup = (groupName: string) => {
      group.value = findGroupByName(groupName);
      if (group.value) {
        store.commit("setGroup", group.value);
      }
    };

    watch(
      () => props.groupId,
      (val) => {
        updateGroup(val);
      },
      { immediate: true },
    );

    return {
      group
    };
  },
});
