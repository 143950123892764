<template>
  <div v-if="open" class="hint">
    <div class="fixed inset-0 bg-black opacity-25"></div>
    <div
      class="fixed inset-0 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none"
    >
      <div class="relative my-6 mx-auto w-auto max-w-sm">
        <div
          class="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none"
        >
          <div
            class="border-blueGray-200 justify-between rounded-t border-b border-solid p-5"
          >
            <div><b>写真のサイズについて（iPhoneの場合）</b></div>
            <div class="text-left">
              <p>
                写真を選択すると以下のような画面になります。下にある「実際のサイズ」は押さずに「完了」を押してください。
              </p>
            </div>
            <img
              alt="Bus logo"
              src="../assets/hint.png"
              class="mt-10 flex h-64 w-full object-contain"
            />
          </div>
          <div
            class="border-blueGray-200 flex flex-col items-center justify-end rounded-b border-t border-solid p-6"
          >
            <button
              class="mx-5 w-28 rounded-md border border-solid bg-blue-400 px-6 py-3 text-white w-full"
              type="button"
              @click="handleBack"
            >
              やめる
            </button>
            <button
              class="mt-5 mx-5 w-28 rounded-md bg-blue-400 px-6 py-3 text-white w-full"
              type="button"
              @click="handleSelect"
            >
              写真を選択します
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup(props, { emit }) {
    const open = ref(false);

    const handleBack = () => {
      open.value = false;
    };

    const handleSelect = () => {
      emit("selected");
      open.value = false;
    };

    return {
      open,
      handleBack,
      handleSelect,
    };
  },
};
</script>
