import FirebaseModel from "./firebasemodel";
import { QueryDocumentSnapshot, DocumentSnapshot } from "firebase/firestore";

export default class Photo extends FirebaseModel {
  photoUrl: string;

  constructor(_model: QueryDocumentSnapshot | DocumentSnapshot) {
    super(_model);

    this.photoUrl =
      this.data.resized && this.data.resized["1200"]
        ? this.data.resized["1200"]
        : this.data.photoURL;
  }
}
