
import { computed, defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Signin",
  props: {
    confirmationResult: { type: Object, required: true },
    groupId: { type: String, required: true },
  },
  setup(props) {
    const router = useRouter();

    const verificationCode = ref("");
    const isLoading = ref(false);
    const sendFail = ref(false);
    const errorMsg = ref("");

    const errorsVerificationCode = computed(() => {
      const ret = [];
      const regex = /^[0-9]{6}$/;
      if (!regex.test(verificationCode.value)) {
        ret.push("確認コードは数字６桁です。");
      }
      return ret;
    });

    const hasErrorVerificationCode = computed(() => {
      return errorsVerificationCode.value.length > 0;
    });

    watch(verificationCode, () => {
      sendFail.value = false;
    });

    const handleCode = async () => {
      console.log("handleCode");
      isLoading.value = true;
      try {
        if (props.confirmationResult) {
          const result = await props.confirmationResult.confirm(
            verificationCode.value,
          );
          console.log("success!", result);
          router.push(`/${props.groupId}/user/photoupload`);
        }
      } catch (error: any) {
        console.log(JSON.stringify(error));
        console.log("error", error.code);
        errorMsg.value = "";
        if (error.code == "auth/invalid-verification-code") {
          errorMsg.value = "確認コードが間違っています。";
        } else if (error.code == "auth/network-request-failed") {
          errorMsg.value = "送信に失敗しました。";
        } else {
          errorMsg.value = "エラーが発生しました。";
        }
        isLoading.value = false;
        sendFail.value = true;
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      handleCode,
      verificationCode,
      errorsVerificationCode,
      hasErrorVerificationCode,
      sendFail,
      errorMsg,
    };
  },
});
