import { useStore } from "vuex";
import { computed } from "vue";

import { doc, getDoc } from "firebase/firestore";
import { db } from "@/utils/firebase9";
import { ref } from "vue";

export const useUser = () => {
  const store = useStore();
  const user = computed(() => store.state.user);
  return user;
};

export const useGroup = () => {
  const store = useStore();
  const group = computed(() => store.state.group);
  return group;
};

export const fetchGroupData = (groupId: string) => {
  const groupAlias = ref("");
  const groupPhone = ref("");

  const getAlias = async () => {
    const groupDoc = await getDoc(doc(db, `groups/${groupId}`));
    console.log(groupId, groupDoc.data());
    if (groupDoc.exists()) {
      groupAlias.value = groupDoc.data().alias;
      groupPhone.value = groupDoc.data().phone;
    } else {
      console.log("No such document.");
    }
  };
  getAlias();

  return {
    groupAlias,
    groupPhone,
  };
};
