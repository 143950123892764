<template>
  <Suspense>
    <router-view />
  </Suspense>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { auth } from "@/utils/firebase9";
import { signOut } from "firebase/auth";

export default {
  setup() {
    console.log("app hoge");
    // console.log(process.env.NODE_ENV);

    // const vcConsole = new VConsole();
    console.log("hello");

    /*
    if (process.env.NODE_ENV == "staging") {                                                            
      const VConsole = await import("vconsole");                  
      new VConsole.default();                                                               
    }
    */

    const store = useStore();
    const user = computed(() => store.state.user);
    const group = computed(() => store.state.group);
    const isSiginedIn = computed(() => store.getters.isSiginedIn);

    const signout = () => {
      signOut(auth);
    };
    return {
      signout,
      user,
      group,
      isSiginedIn,
    };
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  @apply text-center font-sans text-gray-700 antialiased;
}

#nav {
  /* padding: 30px; */
  @apply p-8;
}

#nav a {
  /* font-weight: bold;
  color: #2c3e50; */
  @apply font-bold text-gray-700;
}

#nav a.router-link-exact-active {
  /* color: #42b983; */
  @apply text-green-600;
}

a {
  @apply cursor-pointer;
}
</style>
