
import { defineComponent, ref, Ref } from "vue";
import PreviewModal from "@/components/Preview.vue";
import Toast from "@/components/Toast.vue";
import HintModal from "@/components/Hint.vue";

export default defineComponent({
  name: "PhotoUpload",
  components: {
    PreviewModal,
    Toast,
    HintModal,
  },
  props: {
    user: { type: Object, required: true },
    groupId: { type: String, required: true },
  },
  setup() {
    const previewOpen = ref(false);
    const capture = ref();
    const captureImage = ref(new Blob());
    const pickup = ref();
    const uploadMessage = ref<InstanceType<typeof PreviewModal>>();
    const toastMessage = ref();
    const toastResult = ref();
    const hintModal = ref();

    const showToast = ref(false);
    const triggerToast = () => {
      showToast.value = true;
      console.log(uploadMessage.value?.message);
      toastMessage.value = uploadMessage.value?.message;
      toastResult.value = uploadMessage.value?.result;
      setTimeout(() => (showToast.value = false), 3000);
    };

    const previewToggle = () => {
      previewOpen.value = !previewOpen.value;
    };

    const setImage = (imageRef: Ref) => {
      if (imageRef.value && imageRef.value.files[0] !== null) {
        captureImage.value = imageRef.value.files[0];
        previewToggle();
      }
    };

    const onCaptureImage = () => {
      console.log("onCaptureImage");
      setImage(capture);
    };

    const onFileSelected = () => {
      console.log("onFileSelected");
      setImage(pickup);
    };

    const showHint = () => {
      hintModal.value.open = true;
    };

    const onSelected = () => {
      pickup.value.click();
    };

    return {
      previewOpen,
      previewToggle,
      captureImage,
      capture,
      pickup,
      onCaptureImage,
      onFileSelected,

      showToast,
      triggerToast,
      uploadMessage,
      toastMessage,
      toastResult,

      showHint,
      hintModal,
      onSelected,
    };
  },
});
