
import { defineComponent, ref, watch } from "vue";
import router from "@/router";
import { useGroup, useUser, fetchGroupData } from "@/utils/utils";
import SignoutConfirm from "@/components/SignoutConfirm.vue";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/utils/firebase9";

export default defineComponent({
  name: "About",
  components: {
    SignoutConfirm,
  },
  props: {
    groupId: { type: String, required: true },
  },
  setup() {
    const signoutConfirmOpen = ref(false);
    const signoutConfirmToggle = () => {
      console.log("signoutConfirmToggle");
      signoutConfirmOpen.value = !signoutConfirmOpen.value;
    };
    const realName = ref("");
    const user = useUser();
    const group = useGroup();

    const fetchUserRealName = async () => {
      if (user.value) {
        const userData = (
          await getDoc(doc(db, `users/${user.value.uid}`))
        ).data();
        if (!userData?.groupId || userData.groupId !== group.value.id) {
          alert("登録した地域でご利用ください。");
          router.push(`/`);
        }
        realName.value = userData?.realName || "";
        console.log(realName.value);
      }
    };

    const { groupAlias, groupPhone } = fetchGroupData(group.value.id);

    fetchUserRealName();

    watch(
      user,
      () => {
        if (user.value) {
          fetchUserRealName();
        }
      },
      { immediate: true },
    );

    return {
      signoutConfirmOpen,
      signoutConfirmToggle,
      user,
      realName,
      groupAlias,
      groupPhone,
    };
  },
});
