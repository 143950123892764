import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.group === undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "404"))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}