<template>
  <div class="h-screen w-screen flex justify-center items-center">
    <div class="flex flex-col pb-10">
      <span
        class="my-8 mb-1 text-xl text-left font-medium text-gray-900 dark:text-white"
        >写真送信Webアプリ</span
      >
      <span class="text-xl text-left font-medium text-gray-900 dark:text-white"
        >更新日 2023/7/31</span
      >
    </div>
  </div>
</template>
<script setup lang="ts"></script>
