
import { defineComponent, ref } from "vue";
import { db } from "@/utils/firebase9";
import {
  query,
  onSnapshot,
  collection,
  where,
  orderBy,
} from "firebase/firestore";

import Photo from "@/models/photo";
import DeleteConfirm from "@/components/DeleteConfirm.vue";
import Toast from "@/components/Toast.vue";

export default defineComponent({
  name: "PhotoList",
  components: {
    DeleteConfirm,
    Toast,
  },
  props: {
    user: { type: Object, required: true },
    groupId: { type: String, required: true },
  },
  async setup(props) {
    const deletePhotoId = ref("");
    const deleteMessage = ref<InstanceType<typeof DeleteConfirm>>();
    const toastMessage = ref();
    const toastResult = ref();

    const showToast = ref(false);

    const triggerToast = () => {
      showToast.value = true;
      console.log("deleteMessage:" + deleteMessage.value?.message);
      toastMessage.value = deleteMessage.value?.message;
      toastResult.value = deleteMessage.value?.result;
      setTimeout(() => (showToast.value = false), 3000);
    };

    const path = `users/${props.user.uid}/photos`;

    const photos = ref();

    onSnapshot(
      query(
        collection(db, path),
        where("deleteFlg", "==", false),
        orderBy("createdAt", "desc"),
      ),
      (photo) => {
        if (!photo.empty) {
          photos.value = photo.docs.map((photoDoc) => new Photo(photoDoc));
        }
      },
    );

    const deleteConfirmOpen = ref(false);
    const deleteConfirmToggle = (photoId: string) => {
      console.log("deleteConfirmToggle");
      deleteConfirmOpen.value = !deleteConfirmOpen.value;
      deletePhotoId.value = photoId;
    };

    return {
      deletePhotoId,
      photos,
      deleteConfirmOpen,
      deleteConfirmToggle,
      toastMessage,
      showToast,
      deleteMessage,
      triggerToast,
      toastResult,
    };
  },
});
